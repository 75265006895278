import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Container from "../components/container/Container";
import axios from "axios";
import { toast } from "react-toastify";
import back from "./back.svg";

const URL = "https://gastro.amz-test.at/pdfs";

const PDF = () => {
  const history = useHistory();

  const [user, setUser] = useState();

  const params = useParams();

  useEffect(() => {
    const { id } = params;
    if (id) {
      sendCode(id);
    }
  }, [params]);

  const sendCode = async (id) => {
    try {
      let response;
      if (id.length > 12) {
        response = await axios.post(URL, {
          persistent: id.toLowerCase(),
        });
      } else {
        response = await axios.post(URL, {
          shortid: id.toLowerCase(),
        });
      }
      if (response && response.status === 200) {
        setUser(response.data);
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast.error(
          "Der Test wurde noch nicht im Labor erfasst. Bitte probieren Sie es später erneut."
        );
      }
      if (err.response.status === 409) {
        toast.error("Der Test wurde noch nicht ausgewertet.");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!user) {
    return <div></div>;
  }
  return (
    <Container>
      <div
        className="back"
        onClick={() => {
          setUser(undefined);
          history.goBack();
        }}
      >
        <img src={back} alt="back" />
      </div>
      <h1>PDF herunterladen</h1>
      <p style={{ textAlign: "center" }}>
        Vielen Dank. Klicken Sie auf den Knopf herunterladen um das PDF zu
        speichern.
      </p>
      <div style={{ width: "100%" }}>
        <h3>Person</h3>
        <p className="spacer">
          Name:
          <b>
            {" "}
            {user.fName} {user.lName}
          </b>
        </p>
        {user.svnr && (
          <p className="spacer">
            Sozailversicherungsnummer: <b>{user.svnr}</b>
          </p>
        )}
      </div>
      <Button
        color="primary"
        variant="contained"
        style={{ marginTop: 30 }}
        onClick={() => {
          window.open(user.pdfUrl, "_blank", "fullscreen=yes");
        }}
      >
        PDF herunterladen
      </Button>
    </Container>
  );
};

export default PDF;
