import React from "react";
import { useHistory } from "react-router";
import Container from "../components/container/Container";
import back from "./back.svg";

const Conflict = () => {
  const history = useHistory();
  return (
    <Container>
      <div
        className="back"
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={back} />
      </div>
      <h1>Befund liegt noch nicht vor</h1>
      <p>
        Ihr befund liegt noch nicht vor. Bitte versuchen Sie es in ein paar
        Stunden erneut. Die Auswertung kann bis zu 24 Stunden dauern. (ab
        Probeentnahme)
      </p>
    </Container>
  );
};

export default Conflict;
