import React from "react";
import "./Container.css";

const Container = ({ children }) => {
  return (
    <>
      <div className="container">
        <div className="center">{children}</div>
      </div>
      <div className="cpyright">Copyright 2021 AmZ TEST ZENTRUM</div>
    </>
  );
};

export default Container;
