import React from "react";
import { useHistory } from "react-router";
import Container from "../components/container/Container";
import back from "./back.svg";

const NotFound = () => {
  const history = useHistory();
  return (
    <Container>
      <div
        className="back"
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={back} />
      </div>
      <h1>Probe noch nicht im System erfasst</h1>
      <p>Bitte versuchen Sie es später erneut.</p>
    </Container>
  );
};

export default NotFound;
