import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import CodeIn from "./pages/CodeIn";
import PDF from "./pages/PDF";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Conflict from "./pages/Conflict";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/codein">
            <CodeIn />
          </Route>
          <Route path="/pdf/:id">
            <PDF />
          </Route>
          <Route path="/conflict">
            <Conflict />
          </Route>
          <Route path="/notfound">
            <NotFound />
          </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
